.bsHeader{
    display:flex;
    flex-direction:column;
    justify-content: center;
width:100%;
height:78px;

}
.mid_Header{
    padding-top:3px;
text-align: center;
color:var(--e1);
}