.ticker_Body {
    max-width:100%;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000;
    color: #fff;
    overflow: hidden; /* Hide overflow content outside the viewport */
}
.ticker {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    animation: ticker 23s linear infinite; /* Adjust the duration as needed */
}

.ticker-text {
    display: inline-block;
    padding-left: 100%; /* Initial position outside the container */
    animation: ticker-move 23s linear infinite; /* Match the animation duration above */
}

@keyframes ticker {
    0% {
        transform: translateX(100%); /* Start from the right */
    }
    100% {
        transform: translateX(-100%); /* Move to the left */
    }
}

@keyframes ticker-move {
    0% {
        transform: translateX(0); /* Start from the left */
    }
    100% {
        transform: translateX(-100%); /* Move to the left */
    }
}