body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}
:root{
  --g1:rgb(30, 157, 141);
  --b1: rgb(35, 224, 220);
  --b4: rgb(30, 42, 120);
  --g2: #ADEFD1FF;
  --e1: #343148FF;
  --b2: conic-gradient( rgb(16, 20, 248), rgb(12, 162, 242), var(--b1));
}