:root{
    --g1:rgb(30, 157, 141);
    --b1: rgb(35, 224, 220);
    --e1: #343148FF;
    --b2: conic-gradient( rgb(16, 20, 248), rgb(12, 162, 242), var(--b1));
    --b3: linear-gradient(to bottom right, #343148FF, #1E2A78 );
  }
  .bc_g1{
    background-color: var(--g1);
    }
    .bc_b1{
    background-color: var(--b1);
    }
    .bc_b2{
        background-color: conic-gradient(var(--b1), rgb(58, 222, 219), rgb(11, 180, 177));
    }
    .sec_form1{
        -webkit-clip-path: polygon(100% 0, 100% 84%, 84% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 84%, 84% 100%, 0 100%, 0 0);
    }
    .sec_form2{
        -webkit-clip-path: polygon(100% 0, 100% 100%, 16% 100%, 0 84%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 16% 100%, 0 84%, 0 0);
    }
    .sec_form3{
        -webkit-clip-path: polygon(0 0, 84% 0, 100% 16%, 100% 84%, 86% 100%, 0 100%);
        clip-path: polygon(0 0, 84% 0, 100% 16%, 100% 84%, 86% 100%, 0 100%);
    }
    .sec_form4{
        -webkit-clip-path: polygon(0 16%, 16% 0, 100% 0, 100% 100%, 14% 100%, 0 84%);
        clip-path: polygon(0 16%, 16% 0, 100% 0, 100% 100%, 14% 100%, 0 84%);
    }
    .sec_form5{
       -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0, 57% 0, 53% 9%, 47% 9%, 43% 0);
clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0, 57% 0, 53% 9%, 47% 9%, 43% 0);
    }
.content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr; /* Two columns */
    grid-template-rows:1fr 2fr; /*two rows */
    grid-gap:20px;
    margin: 20px;
    margin-top: 70px;
    margin-bottom: 7em;
    width:100%;
    max-height: 700px;
    
}
#h1{
    grid-column:1/2;
    grid-row:1/2;   
    border-bottom-style: groove;
    border-width:12px;
    border-color:#1E2A78;
    padding:15px;
}
#p1{
    grid-column:1/4;
    grid-row:2/3;
    font-size: large;
    max-width:38%;
    padding:1em;
border-top-style:solid;
border-left-style:solid;
border-width:2px;
border-top-left-radius:17px;
border-bottom-left-radius: 17px;
border-bottom-style: solid;
border-color:#343148FF;
}
.mP1{
    display: grid;
            grid-template-rows: repeat(3, 1fr); /* Create 8 rows with equal height */
            grid-template-columns: 1fr 1fr; /* 1 column */
            gap: 8px; /* Gap between grid items (optional) */
            margin-bottom: 10%;
}
.md{
 display: flex;
 align-content: center;
 justify-content: flex-start;
 flex-direction: column;
 max-height: fit-content;
 width: auto;
 border-style: solid;
 border-color:  var(--g1);
 border-radius:40px;
 background-color: white;
 padding: 17px;
}
.md:hover{
    /* background-color: #343148FF; */
    background-image: linear-gradient(to bottom right, #343148FF, #1E2A78 );
}
#md1{
    grid-row:1/2;
    grid-column:1/2;
}
#md2{
    grid-row:1/2;
    grid-column:2/3;
}
#md3{
    grid-row:2/3;
    grid-column:1/2;
}
#md4{
    grid-row:2/3;
    grid-column:2/3;
}
#md5{
    grid-row:3/4;
    grid-column:1/3;
}
.mh{
    
    max-width:fit-content;
    padding: 15px;
    font-size:xx-large;
    color: var(--b1);
}
.mp, .sp{
    display: flex;
    align-content: flex-start;
    /* background-color: ; */
    padding: 20px;
    font-size: 19px;
    line-height: 1.5; /* Line height for better readability */
    margin-bottom: 1px;
    font-family: Arial, sans-serif; /* Choose a suitable font family */
    font-weight:light;
    color: var(--g1);
    border-radius:30px;
}



.Logo{
    grid-column:2/4;
    grid-row:1/3;
    display:inline_block;
    padding: 10px;
    margin: 1%;
    min-width: fit-content;
    width:40%;
    max-height: fit-content;
    background-color:#1E2A78;
    -webkit-clip-path: polygon(52% 0, 100% 0, 100% 30%, 100% 70%, 100% 100%, 52% 100%, 0% 70%, 0% 30%);
clip-path: polygon(52% 0, 100% 0, 100% 30%, 100% 70%, 100% 100%, 52% 100%, 0% 70%, 0% 30%);
}
.bsc_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 54px;
    background-color: #ADEFD1FF;
    color: #1E2A78;
    font-weight:bold;
    font-size:30px;
    padding: 10px;
}
.Logo_box{
display:flex;
flex-direction: column;
align-items: center;
justify-content:center;
max-width: fit-content;
background-color: white;
padding: 30px;
}
.pentagon{
display: flex;
justify-content: center;
align-items:center;
max-width: fit-content;
padding: 3px;
-webkit-clip-path: polygon(50% 0%, 100% 42%, 82% 100%, 18% 100%, 0% 42%);
clip-path: polygon(50% 0%, 100% 42%, 82% 100%, 18% 100%, 0% 42%);
}
.pentagon:hover{
background-color: #343148FF;
cursor:pointer;

}



.star {
    position: relative;
    width: 150px;
    height: 150px;
    clip-path: polygon(50% 0%, 60% 30%, 100% 42%, 60% 52%, 50% 100%, 40% 52%, 0 42%, 40% 30%);
}

.star::before,
.star::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 0%, 60% 30%, 100% 42%, 60% 52%, 50% 100%, 40% 52%, 0 42%, 40% 30%);
}

.star::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "";
    background-color:  gray;
   
}

.star::after {
   background-image: var(--b2);
    transform: scale(0.8);
}
.P_underbox{
    display: inline-block;
    content:"";
    width:120px;
    min-height:10px;
    border-top:2px double black;
}