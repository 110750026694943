.form_mail_box_holder{
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction:column;
width:100%;
height: 400px;
/* background-color: #00203FFF; */
background-image: linear-gradient(to bottom right, #343148FF, #1E2A78 );
padding: 1em;
margin-bottom: 1em;
margin-top: 2em;
}
.form_mail_header{
    background-color: #ADEFD1FF;
    padding: 1em;
}
.form_mail_box{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction:column;
    width:70%;
    background-color:#ADEFD1FF;
    border-radius:8px;
    padding:15px;
}
.form_mail_label_1{
    padding:15px;
}
.form_mail_label_2{
    padding:15px;
}
.inquiry_input{
    width:200px;
}
.submit_button_1{
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    
    background-color: var(--e1);
    color: #ADEFD1FF;
    border: 2px solid #e7e7e7;
    border-radius: 8px;
}
.submit_button_1:hover {background-color: #00203FFF}