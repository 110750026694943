.pricing{
    border-collapse: collapse;
  width: 100%;
  margin-top: 5%;
  margin-bottom:5%;
}
th, td {
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even){background-color: #f2f2f2}

th {
  background-color: #04AA6D;
  color: white;
}
.animated_div_holder{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    height: 78px;
    width:100%;
}
.H1{
    justify-self: flex-end;
    max-width: fit-content;
    padding: 2px;
    margin-right:5%;
}
.animated_div{
    width: 70px;
    height: 47px;
    background-color: #92B901;
    color: #ffffff;
    position: relative;
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
    animation: animated_div 5s 1;
    -moz-animation: animated_div 5s 1;
    -webkit-animation: animated_div 5s 1;
    -o-animation: animated_div 5s 1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    animation-name: animated_div;
}
@keyframes animated_div {
    0% {transform: rotate(0deg);left: 0px;}
    25% {transform: rotate(20deg);left: 0px;}
    50% {transform: rotate(0deg);left: 500px;}
    55% {transform: rotate(0deg);left: 500px;}
    70% {transform: rotate(0deg);left: 500px;background: #1ec7e6;}
    100% {transform: rotate(-360deg);left: 0px;}
  }