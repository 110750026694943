/* .sv_services{
    width:100%;
    height:100%;
} */

.sv_con_holder_1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex start;
    width:fit-content;
    height:200px;
    margin:2px;
    margin-top:34px;
    padding:20px;
    padding-left:40px;
    border-radius: 1em;
    background-color: #F1F3FFFF;
}
.sv_h1{
    color:#422057FF;
    padding-bottom:40px;
}
.sv_p1{
    color:#422057FF;

}
.sv_con_holder_2{
    display: flex;
    width:100%;
    min-height:fit-content;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:15px;
    margin-bottom: 15px;
    padding:1em;
    border-top-style:groove;
    border-width:15px;
    border-color:#00203FFF;
}
#sv_h2{
    height:100px;
    margin:20px;
    padding:15px;
    border-bottom: 13px dotted var(--e1);
}
.sv_sec_1{
    min-width: fit-content;
    height:fit-content;
    padding:34px;
    background-color: #00203FFF;
}


/* ol{
    background:#ADEFD1FF;
    text-align:left;
    padding:8px;
} */
.sv_table{
    list-style: none; /* Remove default markers */
    list-style-position: outside !important;
    padding:8px;
    margin: 0;
    background:#ADEFD1FF;
    text-align:left;
}
.sv_table li {
    counter-increment: item; /* Increment the counter for each li element */
  }
  
.sv_table li::before{
    content: counter(item) '';
    display: inline-block;
    text-align: center;
    min-width:40px;
    min-height:40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: white;
    margin-right: 5px; /* Adjust spacing between marker and text */
    color: var(--g1);
}
.sv_item::marker{
    display:inline-block;
    color:var(--g1);
}
.sv_item:nth-child(even){border-top-style:groove; border-bottom-style:groove; border-width: 10px;}
.sv_item{
    display: flex;
    flex-direction:row;
    text-align: left;
    padding:8px;
    font-size:x-large;
    color:#00203FFF;
    background-color:#ffc107;
    /* background-color:orange; */
}
.sv_item:hover{
    background-color: var(--e1);
    color:white;
}