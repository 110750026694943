.Contacts{
    width:100%;
    min-height:fit-content;
}
.body{
    margin-top: 5%;
    width:94%;
    min-height:fit-content;
    margin-left:2%;
    margin-right: 2%;
    margin-bottom:5%;
}
.paragraph_box{
    display: grid;
    grid-template-rows:1fr 3fr 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 35px;
    width:100%;
    height:100%;
    
}

.sec_1{
    grid-row-start:1;
    grid-row-end: 2;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    border-width: 11px;
    border-top-style:ridge;
    border-color:var(--b4);
    padding:13px 13px 0 13px;
    width:100%;
    min-height:fit-content;
    background-color:var(--g2);
}
.sec_2{
    grid-row:2/3;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-content:center;
    background-color: white;
}
.sec_3{
    grid-row:3/4;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    border-width: 11px;
    border-color:var(--b4);
    border-bottom-style:ridge;
    padding:15px 15px 0 15px;
    background-color: var(--g2);
}

.seg{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:flex-start;
    padding:13px 13px 0 13px;
    border-left-style:ridge;
    border-right-style:ridge;
    border-width:11px;
    border-color: var(--b4);
    width:32%;
    background-color: var(--g2);
}

.H{
    display: inline-block;
    font-size:xx-large;
    max-width:fit-content;
    color:var(--b4);
}
.H1{
    font-size: xx-large;
    color:var(--b4);
}
.P{
    color:var(--b4);
}
.box_for_tags{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    height:40px;
 
    border-top:7px double var(--b4);
}
.data_tag1{
  width:fit-content;
  color:var(--e1);
}
.data_tag2{
    width:fit-content;
    color:var(--e1);
}